import logo from './logo.svg';
import './App.css';
import Model1 from './components/Model1';
import { Route, Routes } from 'react-router-dom';
import Model2 from './components/Model2';
import Model4 from './components/Model4';
import Model3 from './components/Model3';
import Model5 from './components/Model5';

function App() {
  return (
   <Routes>
   <Route path="/QR1-timelandersar-opening" element={<Model1/>} />
   <Route path="/QR2-joseph" element={<Model2/>} />
   <Route path="/QR3-sheperd" element={<Model3/>} />
   <Route path="/QR4-magi" element={<Model4/>} />
   <Route path="/QR5-jesus-boy" element={<Model5/>} />

   </Routes>
  );
}

export default App;
